import {Form, Input, Button, Select} from 'antd'
import React, {useContext} from 'react'
import {OrderContext} from "../../Context/OrderContex";


const { Option } = Select;

function Filters({ onSubmit,initialValues }) {

  const { ProviderTypes } = useContext(OrderContext);

  const onFinish = (value) => {
    onSubmit({
      ...value,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
      id="periodicSalesReportFilterForm"
    >
       <Form.Item label="Ürün Adı" name="ProductName">
        <Input />
      </Form.Item>
       <Form.Item label="Ürün ID" name="ProductId">
        <Input />
      </Form.Item>
      <Form.Item label="Seri Numarası" name="SerialNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Ürün Kaynağı" name="providerType">
        <Select allowClear style={{ width: "100%" }} placeholder="Please select">
          {ProviderTypes.data.map((status) => (
              <Option key={status.id} value={status.value}>{status.label}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
