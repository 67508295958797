import {createContext, useState} from "react";
import useRequest from "../Hooks/useRequest";
import {orderStatuses, orderItemStatuses, paymentStatuses} from "../Service/common";

export const OrderContext = createContext({
    OrderStatus: {},
    PaymentStatus: {},
    currentOrderPage: 0,
    ProviderTypes: {
        data : []
    },
});

export const OrderContextProvider = ({children}) => {
    const OrderStatus = useRequest(orderStatuses, []);
    const OrderItemStatuses = useRequest(orderItemStatuses, []);
    const PaymentStatus = useRequest(paymentStatuses, []);
    const [currentOrderPage, setCurrentOrderPage] = useState(0);

    const handleCurrentOrderPage = (val) => setCurrentOrderPage(val);

    const ProviderTypes = {
        data: [
            {id: 0, value: "Kiralarsın", label: "Kiralarsın"},
            {id: 1, value: "Pazarama", label: "Pazarama"},
            {id: 2, value: "Byqee", label: "Byqee"},
            {id: 3, value: "Alya", label: "Alya"},
            {id: 4, value: "Arçelik", label: "Arçelik"},
            {id: 5, value: "Vestel", label: "Vestel"},
            {id: 6, value: "Monsternotebook", label: "Monsternotebook"},
            {id: 7, value: "Remington", label: "Remington"},
            {id: 8, value: "RussellHobbs", label: "RussellHobbs"},
        ]
    };

    return (
        <OrderContext.Provider
            value={{
                OrderStatus: {
                    ...OrderStatus,
                    data: OrderStatus.data || [],
                },
                OrderItemStatuses: {
                    ...OrderItemStatuses,
                    data: OrderItemStatuses.data || [],
                },
                PaymentStatus: {
                    ...PaymentStatus,
                    data: PaymentStatus.data || [],
                },
                currentOrderPage,
                setCurrentOrderPage: handleCurrentOrderPage,
                ProviderTypes
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
